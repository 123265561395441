import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Grid, Typography } from '@material-ui/core'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { Button } from '../button/button'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { Icon } from '../icon/icon'
import { breakpointContext } from './footer'

export type ButtonTitleProps = {
  //
}

export type ButtonSubtitleProps = {
  //

}

export type ButtonWrapperProps = {
  // 
}

export type ButtonPanelProps = {
  // 
  icon?: string
  href?: string
  target?: string
}

export type ButtonProps = 
    | (
      {
        button?: never
        subtitle?: never
        title?: never
      } & ButtonWrapperProps
    )
    | (
      {
        button?: never
        subtitle: true
        title?: never
      } & ButtonSubtitleProps
    )
    | (
      {
        button?: never
        subtitle?: never
        title: true
      } & ButtonTitleProps
    )
    | (
      {
        button: true
        subtitle?: never
        title?: never
      } & ButtonPanelProps
    )


export const StyledButton = styled(Button)`
  background-color: rgba(255, 255, 255, 0.06);
  position: relative;
  border-radius: ${props => props.theme.typography.pxToRem(4)};
  text-decoration: none;
  width: 100%;
  margin-bottom: ${props => props.theme.typography.pxToRem(13)};
  margin-left: ${props => props.theme.typography.pxToRem(0)};
  margin-right: ${props => props.theme.typography.pxToRem(0)};
  padding: ${props => props.theme.typography.pxToRem(10)};
  border: unset;

  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(23)};
    width: ${props => props.theme.typography.pxToRem(320)};
    margin-left: ${props => props.theme.typography.pxToRem(20)};
    margin-right: ${props => props.theme.typography.pxToRem(20)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    margin-left: auto;
    margin-right: auto;
  }

  & > span > svg {
    right: ${props => props.theme.typography.pxToRem(10)};
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;

    ${props => props.theme.breakpoints.up('sm')} {
      width: ${props => props.theme.typography.pxToRem(18)};
      height: ${props => props.theme.typography.pxToRem(18)};
    }
  }

  &:hover,
  &:focus {
    border: unset;
    background-color: rgba(255, 255, 255, 0.06);

    & > span > svg {
      color: ${props => props.theme.palette.secondary.main};
      right: ${props => props.theme.typography.pxToRem(5)};
    }

    &::after {
      content: none;
    }

    & > span {
      -webkit-transform: unset !important;
      -ms-transform: unset !important;
      transform: unset !important;
    }
  }

  &:focus {
    outline: ${props => props.theme.typography.pxToRem(1)} auto -webkit-focus-ring-color;
  }

  .MuiGrid-container {
    flex-wrap: nowrap;
  }
`

export const ContactButton = styled.div`
  text-decoration: none;
  width: 100%;
`

export const Heading = styled(Typography)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => props.theme.typography.pxToRem(22)};
  text-decoration: none;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`

export const MutedText = styled(Typography)`
  color: ${props => props.theme.palette.background.default};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  opacity: 75%;
`

export const Chevron = styled(Icon)`
  color: ${props => props.theme.palette.background.default};
  font-size: ${props => props.theme.typography.pxToRem(13)};
  background: none;
  position: absolute;
`

export const ButtonIcon = styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(32)};
  background: none;
  margin-left: ${props => props.theme.typography.pxToRem(10)};
  margin-right: ${props => props.theme.typography.pxToRem(10)};
`

const ButtonRow = styled(Grid)`
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`

const StyledIcon = styled(CreditUnionIcon)`
  margin-left: ${props => props.theme.typography.pxToRem(10)};
  margin-right: ${props => props.theme.typography.pxToRem(15)};
  height: ${props => props.theme.typography.pxToRem(38)};
  width: ${props => props.theme.typography.pxToRem(38)};

  ${props => props.theme.breakpoints.down('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(20)};
  }
`

export const FooterButton: React.FC<PropsWithChildren<ButtonProps>> = ({ button, title, subtitle, children, ...props }) => {
  if (button) {
    return (
      <ButtonWrapper {...props}>{children}</ButtonWrapper>
    )
  }

  if (title) {
    return (
      <ButtonTitle {...props}>{children}</ButtonTitle>
    )
  }

  if (subtitle) {
    return (
      <ButtonSubTitle {...props}>{children}</ButtonSubTitle>
    )
  }

  return (
    <ButtonRowWrapper {...props}>{children}</ButtonRowWrapper>
  )
}

const ButtonRowWrapper: React.FC<PropsWithChildren<ButtonWrapperProps>> = ({ children, ...props }) => {
  const { mobile, tablet } = useContext(breakpointContext)

  return (
    <ButtonRow 
      container
      direction="row" 
      justifyContent={!mobile || !tablet ? 'space-between' : 'center'}
      alignItems="center"
      {...props}
    > 
      {children}
    </ButtonRow>
  )
}

const ButtonWrapper: React.FC<PropsWithChildren<ButtonPanelProps>> = ({ icon, href, children, ...props }) => (
  <StyledButton dark {...props} tabIndex={0} href={href}>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {icon && (
        <StyledIcon variant={true} iconCode={icon} />
      )}
      <Grid
        item
      >
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
    <Chevron icon={faChevronRight}/>
  </StyledButton>
)

const ButtonTitle: React.FC<PropsWithChildren<ButtonTitleProps>> = ({ children, ...props }) => (
  <Heading {...props}>{children}</Heading>
)

const ButtonSubTitle: React.FC<PropsWithChildren<ButtonSubtitleProps>> = ({ children, ...props }) => (
  <MutedText {...props}>{children}</MutedText>
)